@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Light.woff2') format('woff2'),
        url('DINPro-Light.woff') format('woff'),
        url('DINPro-Light.ttf') format('truetype'),
        url('DINPro-Light.svg#DINPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

