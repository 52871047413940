.page-checkin {
  position: relative;
  padding: 16px;

  .header {
    margin-top: 8px;
    width: 100%;

    .title {
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      color: #FFC520;
    }

    .desc {
      margin-top: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .banner {
    position: absolute;
    right: 0;
    top: 0;
    background: url("../images/access.svg") no-repeat;
    width: 128px;
    height: 130px;
    background-size: contain;
  }

  .box {
    margin-top: 20px;
    z-index: 100;
    width: 100%;
    background: #242446;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 24px 20px;
    position: relative;
    z-index: 999;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0.17px;
        color: #FFFFFF;

        .gold {
          color: #FFC520;
        }
      }

      .more {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        text-align: right;
        color: rgba(255, 255, 255, 0.6);

        i {
          margin-right: 4px;
          transform: translateY(1px);
        }
      }
    }

    .box-content {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .day {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        margin-bottom: 16px;
        height: 36px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        color: #FFFFFF;

        span {
          font-size: 12px;
          font-weight: 500;
        }

        i {
          margin-left: 6px;
          font-size: 16px;
          color: #FFC42E;
        }

        &:last-child {
          width: 100%;
        }

        &.active {
          border-color: #FFC42E;
        }
      }
    }

    button {
      margin-top: 6px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      background: #FFC520;
      border-radius: 6px;
      border: none;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.17px;
      color: #000000;

      &.disabled {
        border: 1px solid #ddd;
        background: #ddd;
        color: #333333;
      }
    }
  }


}
