.page-check {
  user-select: none;
  background: #12122F;
  width: 100%;
  height: 100vh;

  @include xl {
    background: #12122F;
  }


  .check-wrapper {
    max-width: 414px;
    margin: 0 auto;
    height: 100vh;
    background: #12122F;
    position: relative;
    color: rgba(255, 255, 255, 0.8);
    padding-top: 118px;

    @include md {
      padding-top: 108px;
      max-width: 780px;
    }



    .check-result {
      margin-top: -70px;
      margin-bottom: 48px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .item {
        .label {
          font-size: 13px;

          @include md {
            font-size: 16px;
          }

          small {
            margin-left: 6px;
            color: rgba(255, 255, 255, 0.4);
          }
        }

        h2 {
          margin-top: 16px;
          font-size: 36px;
          font-weight: 400;

          @include md {
            font-size: 44px;
          }
        }
      }
    }

    .check-btn {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background: linear-gradient(135deg, #60E2FF 15%, #6361FF 75%);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      margin: 0 auto;
      cursor: pointer;
      position: relative;



      // &.error{
      //     background: linear-gradient(136.68deg, #FD387F 15.43%, #C70B38 83.49%);
      // }


      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        border: 1px solid #60E2FF;

      }

      &::after {
        animation: ripple 2s linear 1s infinite;
      }

      @keyframes ripple {
        0% {
          transform: scale(1);
          opacity: 0.8;
        }

        75% {
          transform: scale(1.5);
          opacity: 0.5;
        }

        100% {
          transform: scale(1.75);
          opacity: 0;
        }
      }

      &.loading {
        background: none;
        position: relative;

        &::after {
          animation: none;
          display: none;
        }

        &::before {
          content: "";
          width: 150px;
          height: 150px;
          background: url("../images/loading.png") no-repeat;
          background-size: cover;
          animation: rotation 1s infinite linear;
          position: absolute;
          left: 0;
          top: 0;



        }

        .inside-circle {
          span {
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            line-height: 1.4;
            letter-spacing: 0.1em;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }

      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(359deg);
        }
      }

      .inside-circle {
        background: #12122F;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: none !important;

        span {
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          line-height: 1.4;
          letter-spacing: 0.2em;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .check-item {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 32px;

      .table-cell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        @include md {
          margin-top: 16px;
        }

        .left {
          display: flex;
          align-items: center;
          font-size: 13px;
          color: rgba(255, 255, 255, 0.6);

          @include md {
            font-size: 16px;
          }

          i {
            font-size: 14px;
            margin-right: 6px;

            @include md {
              font-size: 14px;
            }
          }
        }

        .right {
          font-size: 13px;

          @include md {
            font-size: 14px;
          }

          &.success {
            color: #60E2FF;
          }

          &.error {
            color: #FF5E5E;
            ;
          }
        }
      }

    }
  }
}
