* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}



@mixin lg {
  @media (min-width: 1920.98px) {
    @content;
  }
}



@mixin xl {
  @media (min-width: 1280.98px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024.98px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768.98px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 566.98px) {
    @content;
  }
}


html {
  height: 100% !important;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100% !important;
  position: relative;
  background: #15152D !important;

  @include lg {
    background: #ffffff !important;
  }
}


#root {
  height: 100vh;
  position: relative;
}


cloudflare-app {
  display: none !important;
}


.content-wrapper {
  max-width: 595px;
  margin: 0 auto;
  background: #ffffff;
  padding: 24px;
  height: 100%;
  position: relative;

  @media (max-width: 374.98px) {
    padding: 15px;
  }

}

.nbfont {
  font-family: 'DIN Condensed';
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.04em;
}


.rule-content {
  margin-top: 40px;
  width: 100%;
  padding-bottom: 180px;

  .title {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.17px;
    color: #FFFFFF;
    margin-bottom: 18px;
    display: block;

  }

  ul {
    padding-left: 14px;
    list-style: decimal;
    margin-bottom: 24px;

    li {
      margin-bottom: 10px;
      font-size: 13px;
      line-height: 1.72;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  table {
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 24px;

    td,
    th {
      border: 1px solid rgba(255, 255, 255, 0.1);
      text-align: center;
      padding: 8px 0;
      color: rgba(255, 255, 255, 0.4);
      font-size: 13px;
    }

    th {
      color: rgba(255, 255, 255, 0.6);
    }
  }

}
