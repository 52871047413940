.content-wrapper {
  &.vip {
    padding: 0;
  }

}


.tab {
  display: flex;
  align-items: center;
  width: 100%;
  background: #1D1D38;
  justify-content: space-around;
  padding: 0 24px;

  .item-tab {
    height: 40px;
    background: #1D1D38;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;



    img {
      width: 18px;
      margin-right: 4px;
    }

    span {
      color: rgba(255, 255, 255, 0.6);
    }

    &.active {
      border-bottom: 1px solid #FFCC47;

      span {
        color: #ffffff;
        font-weight: 500;
      }
    }
  }
}

.tab-body {
  padding: 32px 24px;

  .content {
    .tr {
      display: flex;
      align-items: center;
      width: 100%;
      background: #1D1D38;
      height: 56px;

      &:nth-child(even) {
        background: none;
      }

      i {
        width: 15%;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        font-size: 18px;
      }

      .title {
        padding-left: 6px;
        width: 45%;
        text-align: left;
        color: rgba(255, 255, 255, 0.8);
        font-size: 15px;

        &.game {
          color: #FFCC47;
        }
      }

      .right {
        width: 40%;
        text-align: right;
        color: rgba(255, 255, 255, 0.8);
        padding-right: 20px;

        i {
          font-size: 18px;
        }

        &.true {
          i {
            color: #07BB07;
          }

        }

        &.false {
          i {
            color: #FF5E5E;
          }
        }
      }
    }
  }

  .btn-vip {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: #5B68F9;
    border-radius: 4px;
    margin-top: 32px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;

    &.vip {
      background: #FFCC47;
      color: #1D1D38;
    }
  }
}
