.page-affiliate {
  position: relative;
  padding: 16px;

  .share-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;

    .share-content {
      width: 92%;
      position: absolute;
      bottom: 32px;
      background: #242446;
      border-radius: 8px;
      padding: 28px 24px 24px 24px;

      .channels {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .channel {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 30%;
          margin-bottom: 24px;

          .name {
            font-size: 13px;
            color: rgba(255, 255, 255, 0.8);
            margin-top: 12px;
          }

          .wechat {
            width: 56px;
            height: 56px;
            background: url("../images/wechat.png") no-repeat;
            background-size: cover;
          }

          .weibo {
            width: 56px;
            height: 56px;
            background: url("../images/weibo.png") no-repeat;
            background-size: cover;
          }

          .download {
            width: 56px;
            height: 56px;
            background: url("../images/download.png") no-repeat;
            background-size: cover;
          }

          .facebook {
            width: 56px;
            height: 56px;
            background: url("../images/facebook.png") no-repeat;
            background-size: cover;
          }

          .twitter {
            width: 56px;
            height: 56px;
            background: url("../images/twitter.png") no-repeat;
            background-size: cover;
          }

          .friend {
            width: 56px;
            height: 56px;
            background: url("../images/friend.png") no-repeat;
            background-size: cover;
          }
        }
      }

      .close-btn {
        margin-top: 8px;
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #FFC222;
        border-radius: 6px;
        background: none;
        font-size: 15px;
        font-weight: 500;
        color: #FFC222;
      }
    }
  }

  .hero {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;

    .banner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(97.23deg, #7050FD 0%, #6343F0 96.9%);
      width: 100%;
      height: 120px;
      padding: 0 12px;
      border-radius: 0;

      .left {
        h3 {
          font-size: 20px;
          color: rgba(255, 255, 255, 0.9);
          margin-bottom: 8px;
        }

        p {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 2px;
          color: rgba(255, 255, 255, 0.9);

          strong {
            color: #FFC222;
          }
        }
      }

      .right {
        width: 123px;
        height: 100px;
        background: url("../images/aff_banner.svg") no-repeat;
        background-size: cover;
      }

    }

    .progress {
      background: #242446;
      height: 110px;
      padding: 0 20px;
      padding-top: 32px;


      .wrapper {
        display: flex;
        justify-content: space-between;
        border-top: 3px solid rgba(255, 255, 255, 0.2);


        .step {
          margin-top: 16px;
          width: 56px;
          font-size: 12px;
          text-align: center;
          color: rgba(255, 255, 255, 0.6);
          position: relative;
          line-height: 1.5;

          &::after {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background: #242446;
            border: 4px solid #7b5ff8;
            border-radius: 50%;
            position: absolute;
            top: -24px;
            left: 40%;
          }
        }
      }
    }
  }



  .users {
    margin-top: 16px;
    width: 100%;
    height: 32px;
    background: #242446;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }

  .card {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0 24px;
    height: 90px;
    background: #242446;
    border-radius: 6px;
    justify-content: space-between;

    .item {
      display: flex;
      flex-direction: column;

      .des {
        font-size: 13px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.6);
      }

      .bottom {
        line-height: 20px;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 14px;
        display: flex;
        align-items: center;

        span {
          font-size: 24px;
          line-height: 20px;
          color: #FFFFFF;
          margin-right: 4px;
        }

        i {
          margin-left: 12px;
          font-size: 12px;
        }

      }
    }
  }

  .rules {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 18px;

    .rule {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 0.6);
    }

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      color: #FFFFFF;
    }
  }

  .btn-disabled {
    background: #92918e !important;
  }

  // button {
  //   position: fixed;
  //   width: 343px;
  //   height: 52px;
  //   bottom: 42px;
  //   background: #FFC222;
  //   border-radius: 6px;
  //   font-size: 17px;
  //   line-height: 24px;
  //   color: #000000;
  //   border: none;
  //   appearance: none;
  //   z-index: 99999;
  // }
}
