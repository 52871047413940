.content-wrapper {
  background: #15152D;

  .banner {
    object-fit: cover;
    width: 100%;
    height: 110px;
    border-radius: 6px;
    box-shadow: 0px 4px 39px rgba(0, 0, 0, 0.2);

  }


  @media (max-width: 999.98px) {
    .banner {
      height: 90px;
    }
  }

}

.share-content {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  background: #15152D;

  .item {
    display: flex;
    height: 88px;
    align-items: center;
    box-shadow: inset 0px -0.5px 0px #F5F5F5;

    img {
      width: 40px;
      height: 40px;
    }

    .content {
      margin-left: 15px;

      .title {
        font-size: 15px;
        color: #111111;
        font-weight: 600;
        width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span {
        display: block;
        font-size: 12px;
        color: #7b7b7b;
        margin-top: 5px;
        width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    i {
      margin-left: auto;
      font-size: 20px;
      color: #C5C7E9;
    }
  }
}

.sharenum {
  margin-top: 16px;

  .item {
    display: flex;
    align-items: center;
    height: 56px;
    background: #242446;
    border-radius: 6px;
    padding: 0 24px 0 16px;
    margin-bottom: 12px;

    i {
      font-size: 20px;
      color: #7681FF;
    }

    span {
      margin-left: 12px;
      font-size: 15px;
      color: rgba(255, 255, 255, 0.7);
    }

    h3 {
      margin-left: auto;
      font-size: 18px;
      color: #FFFFFF;
    }
  }
}

.share-rule {
  margin-top: 28px;

  h3 {
    font-size: 17px;
    color: #ffffff;
  }

  ol {
    margin-top: 16px;
    padding-left: 14px;

    li {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 8px;
    }
  }

}

.share-btn {
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4D55E8;
  border-radius: 4px;
  height: 48px;
  color: #ffffff;

  span {
    margin-left: 8px;
    font-size: 15px;
    font-weight: 500;
  }

  i {
    font-size: 22px;
  }
}
