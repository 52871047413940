@keyframes spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.initing {
  position: relative;

  &::before {
    position: absolute;
    font-size: 32px;
    content: "\EEC5";
    color: #3a4af3;
    animation: spin 800ms infinite linear;
    z-index: 100;
    margin-top: 50%;
    font-family: 'remixicon' !important;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: #15152D;
  }
}

.page-redeem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  background: #15152D !important;
  padding: 16px 16px 32px 16px !important;

  .card {
    position: relative;

    .banner {
      width: 100%;
      height: auto;
      // background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      overflow: hidden;
      object-fit: cover;
    }

    .info {
      margin-top: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-weight: 500;
        font-size: 17px;
        color: #FFFFFF;
      }

      .price {
        i {
          margin-right: 6px;
        }

        font-weight: 500;
        font-size: 15px;
        color: #FFC42E;
        display: flex;
        align-items: center;
      }
    }
  }

  .inputs {
    width: 100%;
    background: none;
    margin: 32px 0 0 0;

    input {
      width: 100%;
      height: 48px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 6px;
      padding-left: 12px;
      font-size: 14px;
      line-height: 16px;
      color: rgba(255, 255, 255, 1);
      font-weight: 500;
      background: none;

      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }




}


.float-btn {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 0 16px;
  left: 0;
  background: #15152D;
  height: 96px;
  padding-top: 8px;


  .btn {
    background: #FFC222;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
    border: none;
    font-weight: 500;
  }

}
