.page-credits {
  background: #15152D;
  padding: 16px 16px 32px 16px !important;

  .title {
    margin: 32px 0 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;

    h4 {
      font-size: 18px;
      font-weight: 500;
    }

    a {
      font-size: 13px;
      color: #7b7b7b;
    }

    span {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .credit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cards {
      display: flex;
      height: 80px;
      flex-direction: column;
      justify-content: center;
      width: 47.7%;
      background: #242446;
      box-shadow: 0px 12px 34px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      padding: 16px 14px;
      position: relative;

      &:nth-child(1)::after,
      &:nth-child(2)::after {
        content: "";
        display: block;
        width: 56px;
        height: 56px;
        background: url("../images/diamond.png") no-repeat;
        background-size: cover;
        position: absolute;
        top: 12px;
        right: 12px;
      }

      &:nth-child(2)::after {
        background: url("../images/points.png");
        background-size: cover;
        opacity: 1 !important;
      }

      .nb {
        font-size: 26px;
        color: #FFFFFF;
      }

      span {
        margin-top: 2px;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;

        i {
          transform: translateY(1px);
        }
      }

    }
  }

  .task-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 74px;
    background: #242446;
    border-radius: 6px;
    padding: 0 12px 0 16px;
    margin-top: 10px;
    position: relative;

    &.banner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(97.23deg, #7050FD 0%, #6343F0 96.9%);
      width: 100%;
      height: 90px;
      padding: 0 24px 0 16px;
      border-radius: 0;
      border-radius: 6px;

      .left {
        h3 {
          font-size: 17px;
          color: rgba(255, 255, 255, 0.9);
          margin-bottom: 8px;
          margin-top: 2px;
        }

        p {
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 6px;
          display: block;
          color: rgba(255, 255, 255, 0.9);

          strong {
            color: #FFC222;
          }
        }
      }

      .right {
        margin-top: 6px;
        width: 100px;
        height: 80px;
        background: url("../images/aff_banner.svg") no-repeat;
        background-size: cover;
      }

    }

    .tag {
      background: #FF4A4A;
      color: #ffffff;
      border-radius: 100px;
      padding: 0 8px 0 8px;
      font-size: 13px;
      margin-left: 4px;
      transform: scale(0.8);
      font-weight: 500;
    }

    .task-left {
      display: flex;
      flex-direction: column;

      .name {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;

        i {
          margin-left: 6px;
          margin-right: 2px;
          font-size: 14px;
          background: linear-gradient(320.71deg, #FFB801 50%, #FFE08F 92.86%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .desc {
        margin-top: 5px;
        font-size: 12px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .btn {
      width: 74px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      border-radius: 26px;
      color: #FFC520;
      border: 1px solid #FFC520;
    }
  }

  .exchange {
    padding-bottom: 40px;

    .exchange-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;

      .exchange-item {
        padding: 12px 12px 14px 12px;
        margin-bottom: 16px;
        width: 47.7%;
        display: flex;
        flex-direction: column;
        background: #242446;
        border-radius: 6px;

        img {
          width: 44px;
          height: 44px;
          border-radius: 10px;
        }

        &.disable {
          i {
            color: #C5C7E9;
          }
        }

        .name {
          margin-top: 12px;
          font-size: 14px;
          color: #FFFFFF;
          font-weight: 500;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

        }

        i {
          font-size: 14px;
          color: #FFC42E;
          margin-right: 2px;
        }

        .bottom {
          margin-top: 6px;
          display: flex;
          align-items: center;

          i {
            font-size: 14px;
          }

          span {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);
            font-weight: 500;
          }
        }
      }
    }
  }

}

.page-credit-details {
  padding: 56px 16px 16px 16px;

  .tabs {
    display: flex;
    width: 100%;
    background: #1D1D38;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;

    .tab {
      font-size: 15px;
      color: #FFFFFF;
      position: relative;
      height: 40px;
      width: 68px;
      display: flex;
      padding: 0;
      border-bottom: 2px solid #1D1D38;
    }

    .active {
      border-bottom: 2px solid #FFB801;
    }
  }

  .detail {
    padding-bottom: 56px;

    .nodata {
      margin-top: 80px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        width: 100px;
        height: 100px;
        background: url("../images/nodata.png") no-repeat;
        background-size: contain;
      }

      span {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .item {
      height: 72px;
      padding: 0 16px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #242446;
      border-radius: 6px;

      .left {
        .name {
          font-size: 15px;
          color: rgba(255, 255, 255, 0.8);
          font-weight: 500;
        }

        .time {
          font-size: 12px;
          margin-top: 8px;
          color: rgba(255, 255, 255, 0.4);
        }
      }

      .right {
        display: flex;
        align-items: center;

        small {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.6);
          margin-right: 4px;
        }

        .nb {
          font-size: 22px;
          color: rgba(255, 255, 255, 0.8);
          transform: translateY(3px);
        }

        i {
          font-size: 22px;
          color: #FFB801;
          margin-left: 8px;
        }
      }



    }
  }
}
