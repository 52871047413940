.wechat-wrapper {
  background: #15152D;

  h4 {
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    margin-top: 24px;
    padding-left: 8px;
    border-left: 3px solid #4D55E8;
  }

  p {
    margin-top: 12px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 24px;
  }

}