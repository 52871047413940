.loading {
  justify-content: center;
}

.bottom-sheet {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;

  .mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
  }

  .body {
    border-radius: 16px 16px 0 0;
    background: #15152D;
    position: absolute;
    bottom: -1000px;
    width: 100%;
    min-height: 300px;
    padding: 0 25px 64px 25px;
    z-index: 999;
    transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
    // transform: translateY(1000px);

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
      margin: 0 !important;

      h4 {
        font-size: 17px;
        color: #FFFFFF;
      }

      i {
        font-size: 14px;
        padding: 1px 0 0 1px;
        color: rgba(255, 255, 255, 0.3);
        font-weight: 700;
        height: 20px;
        width: 20px;
        background: #242446;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }

    .content {
      ol {
        margin-top: 24px;
        padding: 0 0 0 18px;

        li {
          font-size: 13px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 10px;
          line-height: 1.6;
        }
      }
    }
  }

  &.show {
    z-index: 9;
    pointer-events: auto;

    .mask {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      transition: background 450ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
    }

    .body {
      bottom: 0;
    }
  }
}
